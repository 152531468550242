.ChatInput {
  width: 100%;
  /* background: linear-gradient(#eee, lightblue); */
  border: solid #eee 0.5px;
  /* box-shadow: inset -15px 0 lightblue; */
  margin: 2% auto;
}

.InputForm {
  margin: 5px;
}

.ChatInput select {
    vertical-align: top;
    /* background-color: lightblue; */
    font-size: 1.1rem;
}

.ChatInput input {
    vertical-align: top;
    /* background-color: lightblue; */
    font-size: 1.1rem;
/*    background-color: #008080;
    color: white;
    font: inherit;

    margin: 0 5px;
    width: 64px;
    height: 46px;
    */
}

.ChatInput textarea {
  width: 80%;
}

.ChatInput button {
    vertical-align: top;
    margin: 0 5px;
    width: 64px;
    height: 46px;
}

.ChatInput OptionsSuggestionList {
    width: 80%;
    border: solid green 0.5px;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.ChatInput optionSuggestion {
    background-color: lightblue;
    color: red;
    width: 200px;
}

/* @media (min-width: 500px) and (min-height: 400px) {
    .ChatInput {
        width: 450px;
    }
}

@media (min-width: 650px) and (min-height: 401px) {
    .ChatInput {
        width: 650px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .ChatInput {
        width: 700px;
    }
} */
