.Team {
    width: 250px;
    padding: 16px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.Team:hover,
.Team:active {
    background-color: #C0DDF5;
}

.Purpose {
    margin: 16px 0;
    text-align: left;
}

.Tags {
    margin: 16px 0;
    color: #ccc;
}
