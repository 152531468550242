.FromTeal ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

.FromTeal li {
    display: inline-block;
    margin: 20px;
}

.FromTeal a {
    /* text-decoration: none; */
    color: black;
}

.FromTeal a:hover,
.FromTeal a:active,
.FromTeal a.active {
    color: #fa923f;
}

.homeContent {
  border: 2px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;   
}

/* Chat containers */
.container {
 border: 2px solid #dedede;
 background-color: #f1f1f1;
 border-radius: 5px;
 padding: 10px;
 margin: 10px 0;
}

/* Darker chat container */
.darker {
 border-color: #ccc;
 background-color: #ddd;
}

/* Clear floats */
.container::after {
 content: "";
 clear: both;
 display: table;
}

/* Style images */
.container img {
 float: left;
 max-width: 40px;
 width: 100%;
 margin-right: 20px;
 border-radius: 50%;
}

/* Style the right image */
.container img.right {
 float: right;
 margin-left: 20px;
 margin-right:0;
}

/* Style time text */
.time-right {
 float: right;
 color: #aaa;
}

/* Style time text */
.time-left {
 float: left;
 color: #999;
} 
