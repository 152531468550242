body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.FromTeal ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

.FromTeal li {
    display: inline-block;
    margin: 20px;
}

.FromTeal a {
    /* text-decoration: none; */
    color: black;
}

.FromTeal a:hover,
.FromTeal a:active,
.FromTeal a.active {
    color: #fa923f;
}

.homeContent {
  border: 2px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;   
}

/* Chat containers */
.container {
 border: 2px solid #dedede;
 background-color: #f1f1f1;
 border-radius: 5px;
 padding: 10px;
 margin: 10px 0;
}

/* Darker chat container */
.darker {
 border-color: #ccc;
 background-color: #ddd;
}

/* Clear floats */
.container::after {
 content: "";
 clear: both;
 display: table;
}

/* Style images */
.container img {
 float: left;
 max-width: 40px;
 width: 100%;
 margin-right: 20px;
 border-radius: 50%;
}

/* Style the right image */
.container img.right {
 float: right;
 margin-left: 20px;
 margin-right:0;
}

/* Style time text */
.time-right {
 float: right;
 color: #aaa;
}

/* Style time text */
.time-left {
 float: left;
 color: #999;
} 

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0,128,200, 0.2);
  border-right: 1.1em solid rgba(0,128,200, 0.2);
  border-bottom: 1.1em solid rgba(0,128,200, 0.2);
  border-left: 1.1em solid #0080c8;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.Team {
    width: 250px;
    padding: 16px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.Team:hover,
.Team:active {
    background-color: #C0DDF5;
}

.Purpose {
    margin: 16px 0;
    text-align: left;
}

.Tags {
    margin: 16px 0;
    color: #ccc;
}

.Teams {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.ChatMessage {
  border: solid 1px grey;
  margin: 16px;
  background-color: white;
}

.SpeechAct {
  /* text-align: center; */
  background-color: lightblue;
  font-size: 1.1rem;
}

.ChatMessageTime {
  color: #999;
  font-size: 0.8rem;
}

.SpeechActRight {
  float: right;
  background-color: lightblue;
  font-size: 1.1rem;
 }


  .Chat {
    width: 100%;
    margin: auto;
    height: 450px;
    overflow: scroll;
    text-align: left;
    /* background-color: #eee; */
    /* box-shadow: inset -15px 0 lightblue; */
  }
  


  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .Chat {
        width: 700px;
        height: 600px;
    }
  }
  
  /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
  @media screen and (max-width: 400px) {
    .Chat {
        width: 450px;
        height: 300px;
    }
  } 

.ChatInput {
  width: 100%;
  /* background: linear-gradient(#eee, lightblue); */
  border: solid #eee 0.5px;
  /* box-shadow: inset -15px 0 lightblue; */
  margin: 2% auto;
}

.InputForm {
  margin: 5px;
}

.ChatInput select {
    vertical-align: top;
    /* background-color: lightblue; */
    font-size: 1.1rem;
}

.ChatInput input {
    vertical-align: top;
    /* background-color: lightblue; */
    font-size: 1.1rem;
/*    background-color: #008080;
    color: white;
    font: inherit;

    margin: 0 5px;
    width: 64px;
    height: 46px;
    */
}

.ChatInput textarea {
  width: 80%;
}

.ChatInput button {
    vertical-align: top;
    margin: 0 5px;
    width: 64px;
    height: 46px;
}

.ChatInput OptionsSuggestionList {
    width: 80%;
    border: solid green 0.5px;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.ChatInput optionSuggestion {
    background-color: lightblue;
    color: red;
    width: 200px;
}

/* @media (min-width: 500px) and (min-height: 400px) {
    .ChatInput {
        width: 450px;
    }
}

@media (min-width: 650px) and (min-height: 401px) {
    .ChatInput {
        width: 650px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .ChatInput {
        width: 700px;
    }
} */

.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

 /* The bldg view layer on the left */
 .bldg_layer {
    margin: 0;
    padding: 0;
    width: 700px;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
  }
  
  /* The chat layer on the right. The value of the margin-left property should match the value of the sidebar's width property */
  .chat_layer {
    margin-left: 700px;
    padding: 1px 16px;
    height: 600px;
  }


  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .bldg_layer {
      width: 100%;
      height: auto;
      position: relative;
    }
    .chat_layer {margin-left: 0;}
  }


  /* @media (min-width: 500px) and (min-height: 400px) {
    .Chat {
        width: 450px;
        height: 300px;
    }
}

@media (min-width: 650px) and (min-height: 401px) {
    .Chat {
        width: 650px;
        height: 450px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .Chat {
        width: 700px;
        height: 600px;
    }
} */
.Content {
  margin-top: 72px;
}

.Toolbar {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #008080;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Toolbar nav {
  height: 100%;
}

.LogoToolbar {
  height: 100%;
}

@media (max-width: 499px) {
  .DesktopOnly {
    display: none;
  }
}

.Logo {
  padding: 8px;
  height: 72%;
  box-sizing: border-box;
  border-radius: 5px;
}

.Logo img {
  height: 100%;
}

.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems {
      flex-flow: row;
    }
}

.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a {
  color: #008080;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #40A4C8;
}

@media (min-width: 500px) {
  .NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavigationItem a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: #108595;
    border-bottom: 4px solid #40A4C8;
    color: white;
  }
}

.DrawerToggle {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle {
        display: none;
    }
}

.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 500px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.Close {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.LogoSidebar {
  height: 11%;
  margin-bottom: 32px;
}


