

  .Chat {
    width: 100%;
    margin: auto;
    height: 450px;
    overflow: scroll;
    text-align: left;
    /* background-color: #eee; */
    /* box-shadow: inset -15px 0 lightblue; */
  }
  


  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .Chat {
        width: 700px;
        height: 600px;
    }
  }
  
  /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
  @media screen and (max-width: 400px) {
    .Chat {
        width: 450px;
        height: 300px;
    }
  } 
