.ChatMessage {
  border: solid 1px grey;
  margin: 16px;
  background-color: white;
}

.SpeechAct {
  /* text-align: center; */
  background-color: lightblue;
  font-size: 1.1rem;
}

.ChatMessageTime {
  color: #999;
  font-size: 0.8rem;
}

.SpeechActRight {
  float: right;
  background-color: lightblue;
  font-size: 1.1rem;
 }